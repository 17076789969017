@media (prefers-reduced-motion: reduce) {
    .form-control {
        -webkit-transition: none;
        -o-transition: none;
        transition: none; } }
.form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
.form-control::-webkit-input-placeholder {
    color: #6c757d;
    opacity: 1; }
.form-control:-ms-input-placeholder {
    color: #6c757d;
    opacity: 1; }
.form-control::-ms-input-placeholder {
    color: #6c757d;
    opacity: 1; }
.form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
.form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value {
    color: #495057;
    background-color: #fff; }

.form-control-file,
.form-control-range {
    display: block;
    width: 100%; }

.col-form-label {
    padding-top: calc(0.375rem + 1px);
    padding-bottom: calc(0.375rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5; }

.col-form-label-lg {
    padding-top: calc(0.5rem + 1px);
    padding-bottom: calc(0.5rem + 1px);
    font-size: 1.25rem;
    line-height: 1.5; }

.col-form-label-sm {
    padding-top: calc(0.25rem + 1px);
    padding-bottom: calc(0.25rem + 1px);
    font-size: 0.875rem;
    line-height: 1.5; }

.form-control-plaintext {
    display: block;
    width: 100%;
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    margin-bottom: 0;
    line-height: 1.5;
    color: #212529;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0; }
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
    height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem; }

.form-control-lg {
    height: calc(1.5em + 1rem + 2px);
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
    height: auto; }

textarea.form-control {
    height: auto; }

.form-group {
    margin-bottom: 1rem; }

.form-text {
    display: block;
    margin-top: 0.25rem; }

.form-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px; }
.form-row > .col,
.form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem; }

.form-check-input {
    position: absolute;
    margin-top: 0.3rem;
    margin-left: -1.25rem; }
.form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
    margin-bottom: 0; }

.form-check-inline {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 0;
    margin-right: 0.75rem; }
.form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }




@media (min-width: 768px) {
    .dbox {
        margin-bottom: 0;
        padding: 0; } }
.dbox p {
    margin-bottom: 0; }
.dbox p span {
    font-weight: 500;
    color: #969494FF;
    display: block; }
.dbox p a {
    color: rgba(255, 255, 255, 0.8); }
.dbox .icon {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #969494FF;
    margin: 0 auto;
    margin-bottom: 20px; }
.dbox .icon span {
    font-size: 20px;
    color: #000; }
.dbox .text {
    width: 100%; }
.icon:hover{
    background-color: cyan;
    color: #212529;

}