.collapsed{
      overflow: hidden;
      height: 0px;
      transition: 1s;
}.expand{
      overflow: hidden;
      height: auto;
      transition: 1s;
}
* {
      box-sizing: border-box;
      margin: 0;
    }
    
    .piano {
      height: 100px;
      width: 100vw;
      padding: 0 1rem 0 1rem;
    }
    
    .key {
      float: left;
      max-width: 50px;
      width: 10%;
      height: 100%;
      padding-bottom: .5rem;
      margin-right: 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      border: 1px solid gray;
      border-radius: 0 0 .6rem .6rem;
      cursor: pointer;
      user-select: none;
      color: gray;
    }
    
    .key:hover {
      box-shadow: inset -2px 0px 1px 3px wheat;
    }
    
    .key:active {
      box-shadow: inset -3px 0px 1px 2px rgb(212, 180, 120);
    }
    
    #stats {
      position: absolute;
      left: 1rem;
      bottom: 1rem;
      color: darkred;
    }