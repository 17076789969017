
@media screen and (min-width:0px) and (max-width:600px){
    .main-section{
        flex-direction: column;

    }
    .main-section img{
        width: 100%;
    }

    
}


