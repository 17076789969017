

.policy-menu{
    display: flex;
    gap: 10px;
}

.p-controls{
    display:flex;
    gap:10px;


}

.n-policy{
    display:flex;
    gap:20px;

}
.n-p-content{
    width: 40%;
}
.n-p-content .btn{
    background-color: cyan;
    max-width: 180px;
}
.n-p-content .btn:hover{
    background-color: rgb(6, 198, 198);
}
.btn1{
    cursor: pointer;
}
.btn1:hover{
    color: cyan;
    font-weight: bold;
}







@media screen and (min-width: 0px) and (max-width: 600px) {
    .policy-menu{
        display: none;
    }
    .p-controls{
        display:flex;
        gap:2px;
        margin: auto;

    }
    .n-policy{
        display:flex;
        flex-direction: column;
        gap:20px;

    }
    .n-policy img{
        width: 100%;
    }
    .n-p-content{
        width: 100%;
    }


}