.container{
      color: var(--color-primary);
      background-color: #000000;
      width: 100%;
      height: 100%;
      margin: 0px ;

}
.albums{
      display: flex;
      /* margin: 5vh 4vw; */
      align-items: start;
      justify-content: center;
      flex-wrap: wrap;

}
.album{
      width: 85vw;
      max-width: 400px;
      /* height: 650px; */
      display: flex;
      margin: 10px;
      align-items: center;
      flex-direction: column;
      border-radius: 6px;
      /* border: 1px solid red; */
      overflow: hidden;
      justify-content: space-between;
}
.imageSection{
      /* height: 49%; */
      width: 100%;
}
.imageSection img{
      width: 100%;
      /* height: 100%; */
}
.textSection{
      /* height: 39%; */
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      flex-direction: column;
}
.textSectionParagraph{
      /* height: 90%; */
      width: 100%;
      font-size: .9em;
      text-align: start;
      color: var(--color-tertiary);
}
.textSectionLink{
      /* height: 10%; */
      width: 100%;
}
.buttonSection{
      width: 100%;
      /* height: 9%; */
      display: flex;
      /* flex-direction: column; */
      align-items: center;
      /* justify-content: flex-end; */
}
.button{
      background-color: cyan;
      width: fit-content;
      border-radius: 34px;
      padding: 8px 20px;
      justify-content: center;
      display: flex;
      align-items: center;
      font-size: 1em;
      color:black;
      cursor: pointer;
}.button:hover{
      background-color: darkgrey;
}
.button2{
      background-color: cyan;
      width: fit-content;
      border-radius: 34px;
      /* padding: 8px 20px; */
      font-size: 1em;
      color:black;
      cursor: pointer;
}.button2:hover{
      background-color: darkgrey;
}
.discography{
      width: 100%;
      transition: .4s;
      /* border: 2px solid cyan; */
}
.albumcontainer{
      width: 100%;
}
.albumprev{
      width: 100%;
      max-width: 550px;
      height: 100px;
      font-size: .9em;
      /* border: 1px solid blueviolet; */
      border-radius: 14px 14px;
      background-color: rgba(74, 69, 69, 0.084);
      position: relative;
      /* box-shadow: 0px 0px 2px blueviolet; */
}
.albumprev:hover{
      background-color: rgba(74, 69, 69, 0.258);

}
.albumprevimg{
      width: 88px;
      height: 88px;
}
.progressCont{
      width: calc( 100% - 88px - 8px);
      height: 2px;
      /* box-shadow: 0 0 1px cyan; */
      position: absolute;
      bottom: 0%;
      left: calc(88px);
}
.progressCont .progress{
      width: 0%;
      height: 100%;
      background-color: cyan;
}
.albumprevimg .img{
      width:100% !important;
      /* height: 88px; */
      height: 100%;
}
.albumprevimg img{
      width: 100%;
      height: 100%;
}
.icon{
      width: 40px;
      height: 40px;
      border-radius: 50%;
      /* border: 1px solid cyan; */
      box-shadow: 0 0 1px cyan;
      display: flex;
      align-items: center;
      justify-content: center;
}
.details{
      /* width: 100px; */
      max-width: 300px;
}
.icon:hover{
      background-color: rgba(0, 255, 255, 0.16);
}
.headtext{
      cursor: pointer;
}
.headtext:hover{
      color: rgb(163, 152, 152);
}
.active{
      border-bottom: 2px solid blueviolet;

}
/* album section  */
.albumcnt{
      border: 1px solid blueviolet;
      position: relative;
      overflow: hidden;
}

.albumprevimg1{
      width: 100%;
      max-width: 200px;
      
      position: relative;
      /* height: 100px; */
}

.albumprevimg1 img{
      width: 100%;
      height: 100%;
}
.desc{
      position: absolute;
      top: 100%;
      background-color: rgba(0, 0, 0, 0.676);
      width: 100%;
      display: flex;
      transition: .8s;
}
.albumcnt:hover .desc{
      top: auto;
      bottom: 0px;
}
.new{
      color: cyan;
}
@media screen and (min-width:500px) {
      .album{
            margin: 20px;
      }
      
}