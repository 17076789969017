.container {
      color: var(--color-secondary);
      position: relative;
      /* background-color: var(--bg-secondary); */
      background: url('../../assets/images/vieser1.jpg');
      background-repeat: no-repeat;
      background-size: cover;
      font-weight: bold;
      z-index: 9;
      height: 70vh;
      width: 100%;
      
}
.socialMediaIcons{
      width: 100%;
      height: 100%;
      display: flex;
      align-items: end;
      justify-content: center;
}
.socialMediaIcons *{
      font-size: 3em !important;
      margin:  0 6px 8% 6px ;
      transition: .5s;
      color: var(--color-secondary) !important;
}
.socialMediaIcons *:hover{
      font-size: 3em !important;
      color: cyan !important;

}



/* --------- tablet ---------- */
@media screen and (max-width: 750px) {


}

/* --------- mobile ---------- */
@media screen and (max-width: 400px) {}