.cartContainer{
      position: relative;
      height: 100vh;
      width: 100%;
      background-color: var(--bg-primary);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: stretch;
      font-size: .9em;

}
.itemsCount{
      background-color: white;
      margin: 10px 30px;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      text-align: center;
      padding: 3px;
      font-weight: bolder;
}

.cartHeader{
      height: 15%;
      width: 90%;
      color: var(--color-primary);
      font-size: .9em;
      font-weight: bold;
      display: flex;
      border-bottom: 1px solid var(--color-primary);
      align-items: center;
}
.cartBody{
      height: 60%;
      border-bottom: 1px solid var(--color-primary);
      width: 90%;
      color: white;

}
.cartFooter{
      height: 25%;
      width: 90%;
      bottom: 0px;
      font-weight: bold;

}
.checkoutbtn{
      width: 100%;
      background-color: var(--cyan);
      border-radius: 30px;
      color: black;
      font-weight: bold;
      font-size: .9em;
      padding: 15px;
      text-align: center;
      cursor: pointer;
      transition: .2s;

}
.checkoutbtn:hover{
      background-color: var(--color-primary);
      color: var(--white);
}
.checkoutbtn *{
      margin: 10% auto;
      text-decoration: none;
      color: inherit;
}
.viewCart{
      width: 100%;
      text-align: center;
      color: var(--white);
      cursor: pointer;
      font-size: .9em;
      margin: 10px auto;
}
.subTotalDisp{
      display: flex;
      justify-content: space-between;
      color: var(--white);
      margin: 13px auto;
      
}
.subTotal{
      color: var(--color-primary);
}
.listcontainer{
      width: 100%;
      height: 50px;
      /* border: 1px solid red; */
      box-shadow: 0 0 1px cyan;
      border-radius: 4px 4px;
}
.listcontainer .img{
      width: 40px;
      height: 40px;
}
.listcontainer img{
      width: 40px;
      height: 40px;
}