
.container{
      background-color: var(--bg-primary);
      color: var(--color-primary);
      padding-bottom: 50px;
      padding-top: 50px;
}

/* ------- link Section  */
.linkSection{
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: start;
      padding-left: 10vw;
}

.linkContainer{
      margin: 1vw 5vw 7vw 1vw;
}
.linkContainer > div{
      margin: 10px auto;
}
.linkContainer > div >input{
      outline: none !important;
      padding: 5px;
      background-color: var(--bg-primary);
      color: var(--color-secondary);
      border-radius: 4px;
      
      margin-top: 4px;
}
input:active{
      border: 1px solid !important;
}
.linkContainer > button{
      outline: none;
      padding: 6px;
}
.head{
      font-size: 1.3em;
      margin-bottom: 20px !important ;
      /* color: var(--cyan); */
}
/* ------- /link Section  */


/* -------- copyRightSection */
.copyRightSection{
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
}
.copyRightSection hr{
      width: 83%;
      border: 1px solid var(--color-secondary);
      
}
.powerContainer{
      display: flex;
}
.power{
      color: var(--cyan);
      margin-left: 10px;
      cursor: pointer;
}
.power:hover{
      color: var(--color-secondary);
}

/* -------- /copyRightSection */

/* -- mobile */
@media screen and (max-width:550px) {
      
.linkSection{
      display: block;
}
      
}